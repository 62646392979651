import React from "react"
import GlobalStyles from "../styles/global"
import QuizLayout from "../components/QuizLayout"
import Recalculadora from "../components/Recalculadora"
// import * as S from '../styles/quiz';
import { Provider as FormProvider } from "../context/FormContext"

const RecalculadoraPage = () => {
  return (
    <QuizLayout>
      <GlobalStyles />
      <FormProvider>
        <Recalculadora />
      </FormProvider>
    </QuizLayout>
  )
}

export default RecalculadoraPage
