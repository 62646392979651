const YES_NO = Object.freeze({
  yes: { text: "Sim", value: "3" },
  no: { text: "Não", value: "0" },
})

const YES_NO_SOMETIMES = Object.freeze({
  yes: { text: "Sim, todos os dias", value: "3" },
  sometimes: { text: "Sim, de vez em quando", value: "1" },
  no: { text: "Nunca", value: "0" },
})

const PORCENTAGEM = Object.freeze({
  very_high: { text: "Mais de 90%", value: "5" },
  high: { text: "Entre 70 e 90%", value: "4" },
  medium: { text: "Entre 50 e 70%", value: "3" },
  low: { text: "Entre 30 e 50%", value: "2" },
  very_low: { text: "Até 30%", value: "1" },
})

const TIPOS_EXERCICIOS = Object.freeze({
  cardio_pesos: { text: "Atividade com Pesos + Aeróbico", value: "4" },
  pesos: {
    text: "Somente atividade aeróbica (caminhada, corrida, natação, bike)",
    value: "3",
  },
  cardio: { text: "Somente atividade com pesos", value: "2" },
  nada: { text: "Nenhuma atividade", value: "0" },
})

const FREQUENCIA = Object.freeze({
  many: { text: "Sim, mais de 1x ao dia", value: "5" },
  everyday: { text: "Sim, todos os dias", value: "3" },
  rarely: { text: "Sim, de vez em quando", value: "1" },
  no: { text: "Nunca", value: "0" },
})

const FREQ_YEAR = Object.freeze({
  many: { text: "Sim, várias vezes ao ano", value: "5" },
  yes: { text: "Sim, todos os anos", value: "3" },
  rarely: { text: "Raramente", value: "1" },
  no: { text: "Nunca", value: "0" },
})

const FREQ_WEEK = Object.freeze({
  many: { text: "Sim, todos os dias", value: "5" },
  yes: { text: "Sim, 3-5x por semana", value: "3" },
  little: { text: "Sim, 1-2x por semana", value: "2" },
  rarely: { text: "Raramente", value: "1" },
  no: { text: "Nunca", value: "0" },
})

const HABITO_INTESTINAL = Object.freeze({
  constipado: { text: "Constipado (evacua menos de 4x/semana)", value: "3 " },
  normal: { text: "Normal (até 2x por dia)", value: "0 " },
  solto: { text: "Solto (mais de 2x por dia)", value: "1 " },
  diarreia: { text: "Diarréia (cocô mole, sem forma)", value: "4" },
})

const FREQ_DAY = Object.freeze({
  many: { text: "5x ou mais ao dia", value: "5" },
  normal: { text: "3 a 4x ao dia", value: "0" },
  little: { text: "1 a 2x ao dia", value: "2" },
})

const NIVEL_ATIVIDADE = Object.freeze({
  high: { text: "Muito ativa (corrida, musculação, crossfit)", value: "3" },
  medium: { text: "Moderada (caminhadas, yoga, pilates)", value: "2" },
  low: {
    text: "Pouca atividade (tarefas básicas, sem exercícios)",
    value: "1",
  },
})

const NIVEL = Object.freeze({
  excelent: { text: "Excelente", value: "4" },
  high: { text: "Muito boa", value: "3" },
  medium: { text: "Boa", value: "2" },
  low: { text: "Baixa", value: "1" },
})

const DIFICULDADE = Object.freeze({
  very_easy: { text: "Muito Fácil", value: "5" },
  easy: { text: "Fácil", value: "4" },
  ok: { text: "Tranquilo", value: "3" },
  hard: { text: "Difícil", value: "2" },
  very_hard: { text: "Muito Difícil", value: "1" },
})

const CALORIAS = Object.freeze({
  1200: { text: "1200 Kcal", value: "1200" },
  1400: { text: "1400 Kcal", value: "1400" },
  1600: { text: "1600 Kcal", value: "1600" },
  1800: { text: "1800 Kcal", value: "1800" },
})

const QUILOS = Object.freeze({
  0: { text: "Estou satisfeita com meu peso", value: "0" },
  2: { text: "Até 2kg", value: "2" },
  4: { text: "Entre 2 e 4kg", value: "4" },
  6: { text: "Entre 4 e 6kg", value: "6" },
  8: { text: "Entre 6 e 8kg", value: "8" },
  10: { text: "Entre 8 e 10kg", value: "10" },
  12: { text: "Mais de 10kg", value: "12" },
})

const NEXT_STEPS = Object.freeze({
  1: { text: "Emagrecer Mais", value: "1" },
  2: { text: "Continuar como estou", value: "2" },
  3: { text: "Ficar um pouco mais definida", value: "3" },
  4: { text: "Ganhar massa magra", value: "4" },
})

const NEXT_WAY = Object.freeze({
  1: { text: "Emagrecer Mais", value: "1" },
  2: { text: "Manutenção", value: "2" },
  3: { text: "Definição", value: "3" },
  4: { text: "Hipertrofia", value: "4" },
})

const getTextByValue = (object, value) => {
  let text = ""
  if (!value) return "Não respondeu"

  Object.values(object).forEach(entry => {
    if (entry.value === value) text = entry.text
  })
  return text
}

export {
  YES_NO,
  YES_NO_SOMETIMES,
  FREQUENCIA,
  FREQ_YEAR,
  FREQ_WEEK,
  FREQ_DAY,
  HABITO_INTESTINAL,
  DIFICULDADE,
  NIVEL_ATIVIDADE,
  NIVEL,
  PORCENTAGEM,
  TIPOS_EXERCICIOS,
  CALORIAS,
  QUILOS,
  NEXT_STEPS,
  NEXT_WAY,
  getTextByValue,
}
