import React, { useState } from "react"
import * as S from "./styled"
import RadioGroup from "../QuizForm/RadioGroup"
import FormButton from "../QuizForm/FormButton"

import {
  DIFICULDADE,
  FREQUENCIA,
  PORCENTAGEM,
  YES_NO,
  YES_NO_SOMETIMES,
  CALORIAS,
} from "./util/formOptions"

const Step2 = ({ state, setValues }) => {
  const [stepValues, setStepValues] = useState(() => ({
    calorias: state.calorias ? state.calorias : "",
    quanto_seguiu: state.quanto_seguiu ? state.quanto_seguiu : "",
    adaptacao: state.adaptacao ? state.adaptacao : "",
    fome: state.fome ? state.fome : "",
    compulsao: state.compulsao ? state.compulsao : "",
    doce: state.doce ? state.doce : "",
  }))

  const onChangeValue = ({ name, value }) => {
    setStepValues({ ...stepValues, [name]: value })
  }

  return (
    <S.StepWrapper>
      <RadioGroup
        name="calorias"
        text="Quantas calorias possui a sua dieta atual?"
        options={CALORIAS}
        checked={stepValues.calorias}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="quanto_seguiu"
        text="Quanto da alimentação proposta até aqui no Método Esbelta você seguiu?"
        options={PORCENTAGEM}
        checked={stepValues.quanto_seguiu}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="adaptacao"
        text="Como foi sua adaptação a esse novo estilo de vida?"
        options={DIFICULDADE}
        checked={stepValues.adaptacao}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="fome"
        text="Tem sentido fome?"
        options={FREQUENCIA}
        checked={stepValues.fome}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="compulsao"
        text="Tem tido episódios de compulsão alimentar?"
        options={YES_NO_SOMETIMES}
        checked={stepValues.compulsao}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="doce"
        text="A vontade/desejo por doces está controlada?"
        options={YES_NO}
        checked={stepValues.doce}
        onChangeValue={onChangeValue}
      />

      <FormButton
        text="Avançar"
        type="next"
        onClick={e => {
          e.preventDefault()
          setValues(stepValues)
        }}
      />
    </S.StepWrapper>
  )
}
export default Step2
