import React, { useRef, useState } from "react"
import * as S from "./styled"
import { useCalculateResults, sendMail } from "./util/calculadoraHooks"
import Loader from "../Loader"
import { createImages } from "../../pdfCreation"
import FormButton from "../QuizForm/FormButton"

import {
  YES_NO_SOMETIMES,
  FREQUENCIA,
  FREQ_WEEK,
  FREQ_DAY,
  HABITO_INTESTINAL,
  getTextByValue,
  NIVEL,
  PORCENTAGEM,
  TIPOS_EXERCICIOS,
  DIFICULDADE,
  NIVEL_ATIVIDADE,
  YES_NO,
  CALORIAS,
  QUILOS,
  NEXT_WAY,
  NEXT_STEPS,
} from "./util/formOptions"

export const definePDFImages = async (ref1, ref2, ref3, ref4) => {
  let pdfImages = []
  await createImages([ref1, ref2, ref3, ref4]).then(imgs => (pdfImages = imgs))

  return pdfImages
}

const ResultPage = ({ state }) => {
  const results = useCalculateResults(state)
  const [sentMessage, setSentMessage] = useState(null)
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const {
    total,
    water,
    weightToLose,
    time,
    contactId,
    next,
    nextCalculated,
  } = results

  const onSubmitValues = async e => {
    e.preventDefault()
    try {
      const response = await sendMail(state.email, contactId)
      setSentMessage(response)
    } catch (err) {
      setSentMessage(err)
    }
  }

  return results && results.total ? (
    <S.ResultsWrapper>
      <S.ShowResultsInfo id="results" ref={ref1}>
        <S.ResultsInfoTitle>Resultados Calculados</S.ResultsInfoTitle>
        <S.ResultsSubTitle>
          Seu próximo caminho a seguir é:
          <S.ResultsNextWay>
            {getTextByValue(NEXT_WAY, nextCalculated)}
          </S.ResultsNextWay>
        </S.ResultsSubTitle>
        {nextCalculated !== next && (
          <S.ResultsDescription>
            OBS: Apesar de você ter escolhido {getTextByValue(NEXT_WAY, next)},
            é recomendado que siga o caminho{" "}
            {getTextByValue(NEXT_WAY, nextCalculated)}.
          </S.ResultsDescription>
        )}
        <S.Box>
          <S.BoxHeader>Peso que deseja perder</S.BoxHeader>
          <S.BoxValue>{getTextByValue(QUILOS, weightToLose)}</S.BoxValue>
        </S.Box>
        <S.Box>
          <S.BoxHeader>Tempo para alcançar o objetivo</S.BoxHeader>
          <S.BoxValue>{time} meses</S.BoxValue>
        </S.Box>
        <S.Box>
          <S.BoxHeader>Sua Dieta deve ser de</S.BoxHeader>
          <S.BoxValue>{total} Kcal</S.BoxValue>
        </S.Box>
        <S.Box>
          <S.BoxHeader>Ingestão de água por dia</S.BoxHeader>
          <S.BoxValue>{water}L</S.BoxValue>
        </S.Box>
      </S.ShowResultsInfo>

      <S.ShowResultsInfo id="saude" ref={ref2}>
        <S.ResultsInfoTitle>
          Respostas: Vamos Avaliar Sua Alimentação?
        </S.ResultsInfoTitle>
        <S.InfoGroup>
          <S.InfoLabel>Quantas calorias possui a sua dieta atual?</S.InfoLabel>
          <S.InfoValue>{getTextByValue(CALORIAS, state.calorias)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>
            Quanto da alimentação proposta até aqui no Método Esbelta você
            seguiu?
          </S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(PORCENTAGEM, state.quanto_seguiu)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>
            Como foi sua adaptação a esse novo estilo de vida?
          </S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(DIFICULDADE, state.adaptacao)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Tem sentido fome?</S.InfoLabel>
          <S.InfoValue>{getTextByValue(FREQUENCIA, state.fome)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Tem tido episódios de compulsão alimentar?</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.compulsao)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>A vontade/desejo por doces está controlada?</S.InfoLabel>
          <S.InfoValue>{getTextByValue(YES_NO, state.doce)}</S.InfoValue>
        </S.InfoGroup>
      </S.ShowResultsInfo>
      <S.ShowResultsInfo id="food" ref={ref3}>
        <S.ResultsInfoTitle>Respostas: Como Está Sua Saúde?</S.ResultsInfoTitle>
        <S.InfoGroup>
          <S.InfoLabel>
            Tem se sentido menos cansada no início do dia?{" "}
          </S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.cansada)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Tem dormido melhor?</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.dificil_dormir)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Como está sua energia ao longo do dia?</S.InfoLabel>
          <S.InfoValue>{getTextByValue(NIVEL, state.energia)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Sentiu sua ansiedade diminuir?</S.InfoLabel>
          <S.InfoValue>{getTextByValue(YES_NO, state.ansiedade)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Tem sentido dores de cabeça ou enxaqueca?</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.enxaqueca)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Como estão seus hábitos intestinais agora?</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(HABITO_INTESTINAL, state.intestino)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Tem distensão abdominal?</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.distensao)}
          </S.InfoValue>
        </S.InfoGroup>
      </S.ShowResultsInfo>
      <S.ShowResultsInfo id="rotina" ref={ref4}>
        <S.ResultsInfoTitle>
          Respostas: Como Está a Sua Rotina?
        </S.ResultsInfoTitle>
        <S.InfoGroup>
          <S.InfoLabel>Quantas refeições por dia está fazendo?</S.InfoLabel>
          <S.InfoValue>{getTextByValue(FREQ_DAY, state.refeicoes)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>
            Tem feito o desjejum (café da manhã) ao acordar?
          </S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.desjejum)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>
            Tem feito suas refeições sempre nos mesmos horários?
          </S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.horarios)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Está planejando suas refeições?</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.planeja)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Está treinando com pesos?</S.InfoLabel>
          <S.InfoValue>{getTextByValue(FREQ_WEEK, state.treino)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Pratica exercícios aeróbicos?</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(TIPOS_EXERCICIOS, state.cardio)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Qual é o seu nível de atividade hoje?</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(NIVEL_ATIVIDADE, state.activity)}
          </S.InfoValue>
        </S.InfoGroup>
      </S.ShowResultsInfo>
      <S.ShowResultsInfo id="estado" ref={ref4}>
        <S.ResultsInfoTitle>
          Respostas: Sua Condição Atual e Objetivo
        </S.ResultsInfoTitle>
        <S.InfoGroup>
          <S.InfoLabel>Idade</S.InfoLabel>
          <S.InfoValue>{state.age}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Peso</S.InfoLabel>
          <S.InfoValue>{state.weight}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Altura</S.InfoLabel>
          <S.InfoValue>{state.height}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Quantos kg já eliminou?</S.InfoLabel>
          <S.InfoValue>{state.weightLost}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Você gostaria de:</S.InfoLabel>
          <S.InfoValue>{getTextByValue(NEXT_STEPS, state.next)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Quantos Kg você ainda deseja eliminar?</S.InfoLabel>
          <S.InfoValue>{getTextByValue(QUILOS, state.target)}</S.InfoValue>
        </S.InfoGroup>
      </S.ShowResultsInfo>
      {/* <FormButton text="Enviar para meu email" onClick={onSubmitValues} /> */}
      {sentMessage && <S.SentMessage>{sentMessage}</S.SentMessage>}

      {/* <S.CreatePDFWrapper>
                {
                images &&
                <PDFDownloadLink 
                    document={<CreatePDF images={images} />} 
                    fileName={`Resultados ${state.email}.pdf`}>
                        {({ blob, url, loading, error }) => {
                            
                            return (
                                loading ? 
                                <span>Carregando pdf...</span> : 
                                <FormButton type="download">Salvar Resultado em PDF </FormButton>
                                )
                            }}
                </PDFDownloadLink>
                }                    
            </S.CreatePDFWrapper> */}
    </S.ResultsWrapper>
  ) : (
    <Loader />
  )
}

export default ResultPage
