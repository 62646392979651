import React from "react"
import QuizForm from "../QuizForm"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import Step5 from "./Step5"
import ResultPage from "./ResultPage"

const Recalculadora = () => (
  <QuizForm>
    <Step1 title="Definindo seu Próximo Passo" />
    <Step2 title="Vamos Avaliar Sua Alimentação?" />
    <Step3 title="Como Está Sua Saúde?" />
    <Step4 title="Como Está a Sua Rotina?" />
    <Step5 title="Sua Condição Atual e Objetivo" />
    <ResultPage title="Aqui Está o Resultado Da Sua Reavaliação" />
  </QuizForm>
)

export default Recalculadora
