import React, { useState, useEffect } from "react"
import axios from "axios"

const NAF = {
  1: 0.8, //low
  2: 0.9, //medium
  3: 1, //high
}
//IMC
// Menor que 18.5 - Abaixo do peso ;
// Entre 18.5 e 24.9 - Peso normal ;
// Entre 25.0 e 29.9 - Pré-obesidade ;
// Entre 30.0 e 34.9 - Obesidade Grau 1 ;
// Entre 35.0 e 39.9 - Obesidade Grau 2 ;
// Acima de 40 - Obesidade Grau 3

const timeToLoseWeight = totalWeight => {
  let weight = totalWeight
  let time = 0
  let diff = 2

  // around 2kg per month
  while (weight > diff) {
    weight = weight - diff
    time = time + diff / 2
    diff = weight < diff ? weight : diff
  }

  return time
}

const calculateTotalCalories = (calorias, quanto_seguiu, treino, next, imc) => {
  if (imc >= 25) {
    // EMAGRECER MAIS
    switch (calorias) {
      case 1200:
        if (quanto_seguiu >= 4 && treino >= 3) return 1400
        return 1200
      case 1400:
        if (quanto_seguiu >= 4 && treino >= 3) return 1600
        if (quanto_seguiu < 4 || treino < 3) return 1200
        return 1400
      case 1600:
        if (quanto_seguiu >= 5 && treino >= 3) return 1800
        if (quanto_seguiu < 4 && treino < 3) return 1400
        return 1600
      case 1800:
        if (quanto_seguiu >= 4 && treino < 3) return 1600
        if (quanto_seguiu < 4 && treino >= 3) return 1600
        if (quanto_seguiu < 4 && treino < 3) return 1400
        return 1800
      default:
        return calorias
    }
  }
  if (imc > 19 && imc < 25)
    if (next == 2 || next == 3) {
      // MANUTENÇÃO
      if (quanto_seguiu >= 4 && treino >= 3) return calorias + 200
      else return calorias
    } else if (next == 4) {
      return calorias + 400
    }

  return calorias
}

const imc = (weight, height) => {
  const denom = (height * height) / 100

  return weight / denom
}

const calculateResults = ({
  weight,
  height,
  age,
  target,
  weightLost,
  calorias,
  quanto_seguiu,
  treino,
  aerobico,
  next,
}) => {
  // let gender = "F"
  let results = {}
  // let gmb = 46.322 * weight + (17.744 * height) / 100 - 16.66 * age - 944
  let imcCalculated = imc(weight, height)

  //gender === "M"
  //  ? 10 * weight + 6.25 * height - 5 * age + 5
  //  : (10 * weight) + (6.25 * height/100) - (5 * age) - 161;

  // results.gmb = gmb

  results.total = calculateTotalCalories(
    calorias,
    quanto_seguiu,
    treino,
    next,
    imcCalculated
  )

  results.nextCalculated = imcCalculated >= 25 ? "1" : next
  results.next = next

  results.cho = Math.round((results.total * 0.05) / 4)
  results.ptn = Math.round((results.total * 0.25) / 4)
  results.lip = Math.round((results.total * 0.7) / 9)
  results.water = Math.round((weight * 40) / 1000)

  results.target = weight - target
  results.weight = weight

  results.weightToLose = target
  results.time = timeToLoseWeight(results.weightToLose)
  results.imc = imcCalculated

  return results
}

const sendMail = async (email, contactId) => {
  try {
    await axios({
      method: "post",
      url: "https://esbeltafit.com.br/api/activecampaign/sendmail.php",
      // url: 'https://b9b8-2804-7f4-c783-7ba6-983b-97d9-72b8-9460.sa.ngrok.io/demo/sendmail.php',
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        email: email,
        id: contactId,
      }),
    })
    return "Tudo Certo! Os Resultados Foram Enviados Para o Seu Email "
  } catch (err) {
    console.error(err)
    return "Não foi possivel enviar para seu email neste momento."
  }
}

const saveOnActiveCampaign = async (email, weight, results) => {
  if (!results || results.total === 0) return

  try {
    const response = await axios({
      method: "post",
      url: "https://esbeltafit.com.br/api/activecampaign/index.php",
      // url: 'https://b9b8-2804-7f4-c783-7ba6-983b-97d9-72b8-9460.sa.ngrok.io/demo/index.php',
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        email: email,
        weight: weight,
        total: results.total,
        cho: results.cho,
        lip: results.lip,
        ptn: results.ptn,
        weightToLose: results.weightToLose,
        time: results.time,
        detox: results.detox,
        water: results.water,
      }),
    })
    return response.data.contactTag.contact
  } catch (error) {
    console.error(error)
    return null
  }
}

const useCalculateResults = state => {
  const [results, setResults] = useState({
    total: "",
    cho: "",
    ptn: "",
    lip: "",
    water: "",
    target: "",
    weight: "",
    weightToLose: "",
    time: "",
    contactId: "",
    imc: "",
    next: state.next,
    nextCalculated: "",
  })

  useEffect(() => {
    let ignore = false

    async function fetchAndSaveData() {
      const response = calculateResults(state)
      // const contactId = await saveOnActiveCampaign(
      //   state.email,
      //   state.weight,
      //   response
      // )
      await new Promise(resolve => setTimeout(resolve, 3000)) //dá uma enganada para parecer que o cálculo é demorado rsrs
      setResults(results => ({ ...results, ...response }))
    }

    if (!ignore) {
      fetchAndSaveData()
    }

    return () => {
      ignore = true
    }
  }, [state])

  return results
}

export { useCalculateResults, sendMail }
