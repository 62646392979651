import React, { useState } from "react"
import * as S from "./styled"
import { Email } from "@styled-icons/entypo/Email"
import { Paragraph } from "../../styles/elements"
import FormButton from "../QuizForm/FormButton"

const Step1 = ({ state, setValues }) => {
  const [email, setEmail] = useState(() => (state.email ? state.email : ""))

  return (
    <S.StepWrapper style={{ alignItems: "center" }}>
      <Paragraph>Olá, Esbelta!</Paragraph>
      <Paragraph>Vamos avaliar como foi sua evolução até aqui?</Paragraph>

      <Paragraph>Insira seu email de login para começar:</Paragraph>

      <S.FormInput style={{ marginTop: "2rem" }}>
        <Email className="icon" />
        <input
          type="email"
          min="10"
          max="340"
          placeholder="Email de login"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </S.FormInput>
      <FormButton
        text="Começar"
        type="next"
        onClick={e => {
          e.preventDefault()
          setValues({ email: email })
        }}
      />
    </S.StepWrapper>
  )
}

export default Step1
