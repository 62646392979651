import React, { useState } from "react"
import * as S from "./styled"
import RadioGroup from "../QuizForm/RadioGroup"
import FormButton from "../QuizForm/FormButton"

import {
  YES_NO_SOMETIMES,
  FREQ_WEEK,
  FREQ_DAY,
  TIPOS_EXERCICIOS,
} from "./util/formOptions"

const Step4 = ({ state, setValues }) => {
  const [stepValues, setStepValues] = useState(() => ({
    refeicoes: state.refeicoes ? state.refeicoes : "",
    horarios: state.horarios ? state.horarios : "",
    planeja: state.planeja ? state.planeja : "",
    treino: state.treino ? state.treino : "",
    aerobico: state.aerobico ? state.aerobico : "",
  }))

  const onChangeValue = ({ name, value }) => {
    setStepValues({ ...stepValues, [name]: value })
  }

  return (
    <S.StepWrapper>
      <RadioGroup
        name="refeicoes"
        text="Quantas refeições por dia está fazendo?"
        options={FREQ_DAY}
        checked={stepValues.refeicoes}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="horarios"
        text="Tem feito suas refeições sempre nos mesmos horários?"
        options={YES_NO_SOMETIMES}
        checked={stepValues.horarios}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="planeja"
        text="Está planejando suas refeições?"
        options={YES_NO_SOMETIMES}
        checked={stepValues.planeja}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="treino"
        text="Você treina com pesos? Quantas vezes na semana? (musculação, localizada, cross fit, funcional, etc)"
        options={FREQ_WEEK}
        checked={stepValues.treino}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="aerobico"
        text="Pratica exercícios aeróbicos? Quantas vezes na semana? (caminhada, natação, bicicleta, corrida, etc)"
        options={FREQ_WEEK}
        checked={stepValues.aerobico}
        onChangeValue={onChangeValue}
      />
      <FormButton
        text="Avançar"
        type="next"
        onClick={e => {
          e.preventDefault()
          setValues(stepValues)
        }}
      />
    </S.StepWrapper>
  )
}

export default Step4
