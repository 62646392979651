import React, { useState } from "react"
import * as S from "./styled"
import { Divider } from "../../styles/elements"
import { BirthdayCake } from "@styled-icons/fa-solid/BirthdayCake"
import { Height } from "@styled-icons/material/Height"
import { Weight } from "@styled-icons/fa-solid/Weight"
import { TargetArrow } from "@styled-icons/fluentui-system-regular/TargetArrow"
import FormButton from "../QuizForm/FormButton"
import RadioGroup from "../QuizForm/RadioGroup"

import { QUILOS, NEXT_STEPS } from "./util/formOptions"

const Step5 = ({ state, setValues }) => {
  const [stepValues, setStepValues] = useState(() => ({
    age: state.age ? state.age : "",
    height: state.height ? state.height : "",
    weight: state.weight ? state.weight : "",
    weightLost: state.weightLost ? state.weightLost : "",
    target: state.target ? state.target : "",
    next: state.next ? state.next : "",
  }))

  const onChangeValue = ({ name, value }) => {
    setStepValues({ ...stepValues, [name]: value })
  }

  return (
    <S.StepWrapper center>
      <S.FormInput>
        <BirthdayCake className="icon" />
        <input
          type="number"
          min="14"
          max="100"
          placeholder="Idade"
          name="age"
          value={stepValues.age}
          onChange={e => onChangeValue(e.target)}
          required="required"
        />
      </S.FormInput>
      <S.FormInput>
        <Height className="icon" />
        <input
          type="number"
          name="height"
          min="130"
          max="242"
          placeholder="Altura (cm)"
          value={stepValues.height}
          onChange={e => onChangeValue(e.target)}
          required="required"
        />
      </S.FormInput>
      <S.FormInput>
        <Weight className="icon" />
        <input
          type="number"
          name="weight"
          min="40"
          max="180"
          value={stepValues.weight}
          placeholder="Peso atual (Kg)"
          onChange={e => onChangeValue(e.target)}
          required="required"
        />
      </S.FormInput>
      <S.FormInput>
        <TargetArrow className="icon" />
        <input
          type="number"
          name="weightLost"
          value={stepValues.weightLost}
          placeholder="Quantos Kg já eliminou?"
          min="0"
          max="50"
          onChange={e => onChangeValue(e.target)}
          required="required"
        />
      </S.FormInput>
      <div style={{ margin: "2rem 0" }} />
      <RadioGroup
        name="next"
        text="Você gostaria de:"
        options={NEXT_STEPS}
        checked={stepValues.next}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="target"
        text="Quantos Kg você ainda deseja eliminar?"
        options={QUILOS}
        checked={stepValues.target}
        onChangeValue={onChangeValue}
      />
      <FormButton
        text="Calcular"
        type="submit"
        onClick={e => {
          e.preventDefault()
          setValues(stepValues)
        }}
      />
    </S.StepWrapper>
  )
}
export default Step5
