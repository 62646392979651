import React, { useState } from "react"
import * as S from "./styled"
import RadioGroup from "../QuizForm/RadioGroup"
import FormButton from "../QuizForm/FormButton"

import {
  YES_NO,
  YES_NO_SOMETIMES,
  HABITO_INTESTINAL,
  NIVEL,
} from "./util/formOptions"

const Step3 = ({ state, setValues }) => {
  const [stepValues, setStepValues] = useState(() => ({
    cansada: state.cansada ? state.cansada : "",
    dificil_dormir: state.dificil_dormir ? state.dificil_dormir : "",
    energia: state.energia ? state.energia : "",
    ansiedade: state.ansiedade ? state.ansiedade : "",
    enxaqueca: state.enxaqueca ? state.enxaqueca : "",
    intestino: state.intestino ? state.intestino : "",
    distensao: state.distensao ? state.distensao : "",
  }))

  const onChangeValue = event => {
    setStepValues({ ...stepValues, [event.name]: event.value })
  }

  return (
    <S.StepWrapper>
      {/*  */}
      <RadioGroup
        name="cansada"
        text="Tem se sentido menos cansada no início do dia?"
        options={YES_NO_SOMETIMES}
        checked={stepValues.cansada}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="dificil_dormir"
        text="Tem dormido melhor?"
        options={YES_NO_SOMETIMES}
        checked={stepValues.dificil_dormir}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="energia"
        text="Como está sua energia ao longo do dia?"
        options={NIVEL}
        checked={stepValues.energia}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="ansiedade"
        text="Sentiu sua ansiedade diminuir?"
        options={YES_NO}
        checked={stepValues.ansiedade}
        onChangeValue={onChangeValue}
      />

      <RadioGroup
        name="enxaqueca"
        text="Tem sentido dores de cabeça ou enxaqueca?"
        options={YES_NO_SOMETIMES}
        checked={stepValues.enxaqueca}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="intestino"
        text="Como estão seus hábitos intestinais agora?"
        options={HABITO_INTESTINAL}
        checked={stepValues.intestino}
        onChangeValue={onChangeValue}
      />
      <RadioGroup
        name="distensao"
        text="Tem distensão abdominal?"
        options={YES_NO_SOMETIMES}
        checked={stepValues.distensao}
        onChangeValue={onChangeValue}
      />
      <FormButton
        text="Avançar"
        type="next"
        onClick={e => {
          e.preventDefault()
          setValues(stepValues)
        }}
      />
    </S.StepWrapper>
  )
}
export default Step3
